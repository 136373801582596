<template>
  <!-- 商家管理页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <a-col :md="5" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 状态 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="状态">
                    <a-select v-model="queryParam.delFlag" allow-clear placeholder="请选择">
                      <a-select-option :value="1">启用</a-select-option>
                      <a-select-option :value="3">禁用</a-select-option>
                      <a-select-option :value="2">已注销</a-select-option>
                      <a-select-option :value="4">违规</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="4" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => ((queryParam = {}), initTableData())">重置</a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
            :row-selection="rowSelection"
          >
            <!-- avatar插槽 -->
            <span slot="avatar" slot-scope="text">
              <img :src="text" alt="" />
            </span>
            <!-- 状态插槽 -->
            <span slot="delFlag" slot-scope="text, record" @click="clickSwitch(record)">
              <span v-if="record.delFlag == 2">
                <a-tag color="red">已注销</a-tag>
              </span>
              <span v-else>
                <a-switch
                  checked-children="启用"
                  un-checked-children="禁用"
                  v-model="record.checked"
                  @change="changeSwitch"
                />
              </span>
            </span>
            <!-- 操作插槽 -->
            <span slot="action" slot-scope="text, record">
              <a @click="$refs.editForm.edit(record)">设置抽成比例</a>
              <a-divider type="vertical" />
              <a @click="$refs.detailForm.detail(record)">详情</a>
              <a-divider type="vertical" />
              <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysItemDelete(record)">
                <a>删除</a>
              </a-popconfirm>
              <a-divider type="vertical" />

              <!-- 下拉 -->
              <a-dropdown>
                <a class="ant-dropdown-link">
                  更多
                  <a-icon type="down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <router-link :to="{ path: '/ordelist_admin', query: { id: record.id } }">订单列表 </router-link>
                  </a-menu-item>
                  <a-menu-item>
                    <router-link :to="{ path: '/store_items', query: { id: record.id } }">商品列表 </router-link>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <!-- 分页组件 -->
    <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
      <a-pagination
        :pageSize="page.pageSize"
        :current="page.pageNo"
        show-size-changer
        :page-size-options="['10', '20', '50', '100']"
        show-quick-jumper
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="sizeChange"
      >
      </a-pagination>
    </div>
    <!-- 引入子组件 -->
    <detail-form ref="detailForm"></detail-form>
    <add-form @ok="initTableData" ref="addForm"></add-form>
    <edit-form @ok="getList" ref="editForm"></edit-form>
    <fans-form ref="fansForm" @ok="getList"></fans-form>
    <follow-form ref="followForm" @ok="getList"></follow-form>
  </div>
</template>

<script>
import { storeAdminPageList, storeAdminDelete, releaseLiveForbidden } from '@/api/modular/mallLiving/report'

import addForm from './addForm'
import editForm from './editForm'
import detailForm from './detailForm.vue'
import fansForm from './fansForm.vue'
import followForm from './followForm.vue'
const columns = [
  {
    title: '序号',
    align: 'left',
    width: '70px',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '商家昵称',
    align: 'left',
    width: '150px',
    dataIndex: 'nickname',
  },
  {
    title: '头像',
    width: '100px',
    dataIndex: 'avatar',
    align: 'left',
    scopedSlots: {
      customRender: 'avatar',
    },
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    align: 'left',
  },
  {
    title: '状态',
    dataIndex: 'delFlag',
    align: 'left',
    scopedSlots: {
      customRender: 'delFlag',
    },
  },

  {
    title: '抽成比例',
    width: '150px',
    dataIndex: 'agentPlatformRatio',
    align: 'left',
  },
  {
    title: '注册时间',
    dataIndex: 'registerTime',
    align: 'left',
  },
  {
    title: '开店时间',
    dataIndex: 'createTime',
    align: 'left',
  },
  {
    title: '注销时间',
    dataIndex: 'cancellationTime',
    align: 'left',
  },
  {
    title: '操作',
    align: 'left',
    width: '250px',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: {
      customRender: 'action',
    },
  },
]
export default {
  data() {
    return {
      columns,
      data: [],
      recordItem: null,
      total: 0,
      loading: false,
      queryParam: {},
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      selectedRowKeys: [],
      selectRowId: [],
    }
  },
  components: {
    addForm,
    editForm,
    detailForm,
    fansForm,
    followForm,
  },
  mounted() {
    this.initTableData()
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys, // 一定要加上这一行代码，清除才会有作用
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectRowId = selectedRows.map((item) => {
            return item.id
          })
        },
      }
    },
  },
  methods: {
    releaseForbidden(record) {
      releaseLiveForbidden({ storeId: record.id }).then((res) => {
        if (res.success) {
          this.$message.success('解禁成功')
          this.getList()
        }
      })
    },
    openBatchEditForm(type) {
      if (this.selectRowId.length > 0) {
        if (type == 1) {
          this.$refs.fansForm.batchShow(this.selectRowId)
        } else {
          this.$refs.followForm.batchShow(this.selectRowId)
        }
      } else {
        this.$message.error('请选择要操作的数据')
        return
      }
    },

    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    getList() {
      this.loading = true
      let query = {
        queryParam: this.queryParam,
        page: this.page,
      }
      this.selectRowId = []
      this.selectedRowKeys = []
      storeAdminPageList(query)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.data.forEach((item) => {
              item.checked = item.delFlag == 1
            })
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
    },
    // 删除
    sysItemDelete(record) {
      this.recordItem = record
      this.disableCommodityCate(2) //2:删除
    },
    clickSwitch(record) {
      this.recordItem = record
    },
    changeSwitch(e) {
      let that = this
      let delFlag = e ? 1 : 3
      setTimeout(function () {
        that.disableCommodityCate(delFlag)
      }, 300)
    },
    async disableCommodityCate(delFlag) {
      let params = {
        delFlag: delFlag,
        id: this.recordItem.id,
      }
      let data = await storeAdminDelete(params)
      var text = delFlag == 1 ? '启用' : delFlag == 2 ? '已注销' : '禁用'
      if (data.code == 200) {
        this.$message.success(this.recordItem.nickname + ' 已' + text)
        this.getList()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 40px;
  height: 40px;
}
</style>
